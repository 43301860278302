export function wrapInAnimatedSpans(string) {
  let result = [];
  for (let i = 0; i < string.length; i++) {
    result.push(
      <span
        key={`${string[i]}-${i}`}
        style={{
          "--animation-delay": `${(i + 1) * 10}ms`,
        }}
        className="inline-block relative overflow-hidden whitespace-pre"
      >
        {string[i]}
      </span>
    );
  }

  return result;
}
