import "./App.css";
import Links from "./Links";
import ParticlesWrapper from "./Particles";
import { wrapInAnimatedSpans } from "./util";

function App() {
  return (
    <>
      <ParticlesWrapper />
      <main className="relative font-forma min-h-screen max-w-xl mx-auto px-4 py-28">
        <h1 className="text-5xl lg:text-7xl font-formaExtraBold">
          Palla <span className="font-formaMediumItalic">d'Uovo</span>
        </h1>
        <p className="text-lg mt-4">
          <span className="font-formaMediumItalic">"Palla d'Uovo"</span> — A musical concept based around synth-heavy music genres such as Italo, EBM,
          and Dark Disco. Events, hostings, radio shows, guest mixes, and more.
        </p>
        {/* <div className="ticket-wrap mt-16">
          <div className="ticket ticket--top text-black px-4 pt-4 pb-6">
            <h2 className="text-xl tracking-wide font-formaBold pb-2 border-b border-gray-300">Palla d'Uovo Club Night</h2>
            <div className="mt-4 flex gap-6">
              <div>
                <p className="text-orange-500 font-formaItalic">Date</p>
                <p className="text-lg tracking-wide">May 31st</p>
              </div>
              <div>
                <p className="text-orange-500 font-formaItalic">Location</p>
                <p className="text-lg tracking-wide">
                  Oosterstraat 13a,
                  <span className="hidden md:inline">&nbsp;</span>
                  <br className="md:hidden" />
                  Groningen
                </p>
              </div>
              <div>
                <p className="text-orange-500 font-formaItalic">Time</p>
                <p className="text-lg tracking-wide">23:00 - 05:00</p>
              </div>
            </div>
            <div className="mt-4 flex gap-6">
              <div>
                <p className="text-orange-500 font-formaItalic">Artists</p>
                <p className="text-lg tracking-wide">
                  HIHATDANCER, Tending Tropic,
                  <span className="hidden md:inline">&nbsp;</span>
                  <br className="md:hidden" />
                  Looming Iris, Tarnim
                </p>
              </div>
            </div>
          </div>
          <div className="ticket ticket--bottom p-4">
            <a
              href="https://eventix.shop/amw3c4ma"
              className="ticket-button block rounded-lg bg-orange-500 text-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex justify-center py-4">{wrapInAnimatedSpans("Get Your Tickets")}</div>
            </a>
          </div>
        </div> */}
        {/* <h2 className="font-formaExtraBold text-2xl tracking-wide mt-8">Events</h2>
        <div className="mt-4 flex gap-6 justify-between items-center text-lg">
          <div className="flex flex-col items-center">
            <p className="font-formaExtraBold">04</p>
            <p className="text-sm">AUG</p>
          </div>
          <div className="flex-grow">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://eventix.shop/amw3c4ma"
              className="underline font-formaExtraBold tracking-wide hover:opacity-50 transition-opacity"
            >
              T.B.A.
            </a>
            <p className="text-sm">23:00 - 05:00, Smoke Groningen</p>
          </div>
          <div className="overflow-hidden">
            <a target="_blank" rel="noreferrer" href="https://eventix.shop/amw3c4ma" className="uppercase text-base animate-button">
              <div>{wrapInAnimatedSpans("tickets")}</div>
            </a>
          </div>
        </div> */}
        <h2 className="font-formaExtraBold text-2xl tracking-wide mt-16">What's new?</h2>
        <Links />
        <h2 className="font-formaExtraBold text-2xl tracking-wide mt-16">Find us here</h2>
        <ul className="mt-4 flex flex-col gap-4 text-center">
          <li>
            <a
              href="https://chat.whatsapp.com/Lr2qZvh3u3m8D01rU99pST"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("Join our Community")}</div>
            </a>
          </li>
          <li>
            <a
              href="https://soundcloud.com/palla-duovo"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("SoundCloud")}</div>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/palladuovo/"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("Instagram")}</div>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@palladuovo"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("YouTube")}</div>
            </a>
          </li>
          <li>
            <div className="block border border-white animate-button hover:border-opacity-50 transition-all">
              <p className="overflow-hidden flex py-4 pl-4">info@palladuovo.com</p>
            </div>
          </li>
        </ul>

        {/* <ul className="mt-16 flex flex-col gap-4 text-center text-xl">
          {links.map((link) => {
            const { name, url, buttonText } = link;

            return (
              <li key={name}>
                <a
                  href={url}
                  className="block border border-white animate-button hover:border-opacity-50 transition-all"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overflow-hidden flex justify-center py-4">{wrapInAnimatedSpans(buttonText)}</div>
                </a>
              </li>
            );
          })}
        </ul> */}
      </main>
    </>
  );
}

export default App;
