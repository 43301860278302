import Airtable from "airtable";
import { useEffect, useState } from "react";
import { wrapInAnimatedSpans } from "./util";

export default function Links() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    let base = new Airtable({
      apiKey: "patghy6KqfTsQrzjE.a82283c067249e1aad4a94b0563199afa5dbbea37c000ff51d94c53c4ccc34b9",
    }).base("applxkPC4IIQCr1sC");
    let cleanedData = [];

    base("Links")
      .select({
        maxRecords: 100,
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            const { ButtonText, Name, URL } = record.fields;
            cleanedData.push({
              name: Name,
              url: URL,
              buttonText: ButtonText,
            });
          });

          fetchNextPage();
        },
        function done(err) {
          setLinks(cleanedData);
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  if (links.length === 0) return null;

  return (
    <ul className="mt-4 flex flex-col gap-4 text-center">
      {links.map((link) => {
        const { name, url, buttonText } = link;

        return (
          <li key={name}>
            <a
              href={url}
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans(buttonText)}</div>
            </a>
          </li>
        );
      })}
    </ul>
  );
}
